import { Box, Button } from "@mui/material";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import dateRangPicker from "../assets/Dashboard/datePickerButton.png";
import dateRangPickerActive from "../assets/Dashboard/dateRangPickerActiveButton.png";
import "react-datepicker/dist/react-datepicker.css";
// ==============================|| DATE PICKER - DATE RANGE ||============================== //

export default function DateRangePicker({
  setSelect,
  setSelectDateRange,
  selectDateRange,
  selectsRange = true,
  disabled = false,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, endDate] = selectsRange ? selectDateRange : [null, null];

  useEffect(() => {
    if (selectsRange) {
      if (!!startDate && !!endDate) {
        setIsOpen(!isOpen);
        setSelect("dateRange");
      }
    }
  }, [selectDateRange]);

  const handleChange = (e) => {
    setSelectDateRange(e);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <>
      {selectsRange ? (
        <DatePicker
          onClickOutside={() => {
            if (!!startDate && !endDate) {
              setSelect("last7");
              setSelectDateRange([null, null]);
              setIsOpen(false);
            }
          }}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={handleChange}
          style={{ zIndex: 99 }}
          maxDate={new Date()}
          customInput={
            <Button
              className="example-custom-input"
              onClick={handleClick}
              sx={{
                borderRadius: "50%",
                padding: "0",
                minWidth: "40px",
                position: "relative",
              }}
            >
              {!!startDate && !!endDate ? (
                <img width={"40px"} src={dateRangPickerActive} alt="date" />
              ) : (
                <img width={"40px"} src={dateRangPicker} alt="date" />
              )}
            </Button>
          }
          disabled={disabled}
        />
      ) : (
        <DatePicker
          selectsRange={false}
          selected={selectDateRange || new Date()}
          style={{ zIndex: 99 }}
          onChange={(date) => {
            setSelectDateRange(date);
            setIsOpen(false);
          }}
          maxDate={new Date()}
          customInput={
            <Button
              className="example-custom-input"
              onClick={handleClick}
              sx={{
                borderRadius: "50%",
                padding: "0",
                minWidth: "40px",
                position: "relative",
              }}
            >
              {!!startDate && !!endDate ? (
                <img width={"40px"} src={dateRangPickerActive} alt="date" />
              ) : (
                <img width={"40px"} src={dateRangPicker} alt="date" />
              )}
            </Button>
          }
        />
      )}
    </>
  );
}
