import { useEffect, useState } from "react";

// material-ui
import {
  Box,
  FormControl,
  Link,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

// project-imports
import MainCard from "../../Component/MainCard";

// assets

import PopularLinks from "./PopularLinks";
import AdvancedAnalysis from "./AdvancedAnalysis";
import {
  CountryOsBrowserQueryData,
  DeepLinkOsBrowserQueryData,
} from "../../Helpers/dashboard";
import DateRangePicker from "../../Component/DateRangePicker";
import moment from "moment";
import { useDynamicCountryData } from "../../Hooks/Dashboard/useDynamicCountryData";
import { useGetSpaceStateAnalytics } from "../../Hooks/Spaces/useGetSpaceAnalytics";
import { useGetDeepLinkStateAnalytics } from "../../Hooks/DeepLinking/States/useGetStatusDataDeepLinking";
import { a11yProps } from "../../Helpers/common";
import { useTranslation } from "react-i18next";

// ==============================|| DATA WIDGET - TYRANSACTIONS ||============================== //

export default function Transactions({
  mainTitle,
  tabList,
  boxHeight = "558px",
  showTabs,
  bodySx,
  setFilter,
  filterValue,
  platformValue,
  setPlatformValue,
  url_shortner_id = "",
  advanceId = "advanceAnalysis",
}) {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [selectValue, setSelect] = useState("last7");
  const [selectDateRange, setSelectDateRange] = useState([null, null]);
  const [disabled, setDisabled] = useState(false);

  const { isLoading, isFetching } = useDynamicCountryData(filterValue);
  const {
    isLoading: SpaceStateDataIsLoading,
    isFetching: SpaceStateDataIsFetching,
  } = useGetSpaceStateAnalytics(filterValue);
  const {
    isLoading: DeepLinkStateDataIsLoading,
    isFetching: DeepLinkStateDataIsFetching,
  } = useGetDeepLinkStateAnalytics(filterValue);

  useEffect(() => {
    if (advanceId === "advanceAnalysis") {
      setDisabled(isLoading || isFetching);
    } else if (advanceId === "spaceAnalytics") {
      setDisabled(SpaceStateDataIsLoading || SpaceStateDataIsFetching);
    } else if (advanceId === "advanceAnalysisDeepLink") {
      setDisabled(DeepLinkStateDataIsLoading || DeepLinkStateDataIsFetching);
    }
  }, [
    isLoading,
    isFetching,
    DeepLinkStateDataIsLoading,
    DeepLinkStateDataIsFetching,
    SpaceStateDataIsFetching,
    SpaceStateDataIsLoading,
  ]);

  useEffect(() => {
    if (!!selectDateRange[0] && !!selectDateRange[1]) {
      if (advanceId === "advanceAnalysis") {
        setFilter({
          apiType: "POST",
          lastPath: "/dynamic_dates",
          day: "",
          auth: "web",
          search: "",
          page: 1,
          limit: "",
          platform: platformValue,
          type: "click",
          url_shortner_id: url_shortner_id,
          payload: {
            start_date: moment(selectDateRange[0]).format("yyyy-MM-DD"),
            end_date: moment(selectDateRange[1]).format("yyyy-MM-DD"),
          },
        });
      } else if (advanceId === "advanceAnalysisDeepLink") {
        setFilter({
          lastPath: `/platforms_dynamic?deep_link_id=${url_shortner_id}`, // lastPath /lastdays || /month || /dynamic_dates,
          auth: "web", // auth
          page: 1, // page,
          limit: "", // limit,
          platform: platformValue, // platform country ||os ||browser,
          extra: "",
          get: false,
          payload: {
            start_date: moment(selectDateRange[0]).format("yyyy-MM-DD"),
            end_date: moment(selectDateRange[1]).format("yyyy-MM-DD"),
          },
        });
      }
    }
  }, [selectDateRange]);

  useEffect(() => {
    if (selectValue !== "dateRange" && setFilter !== null) {
      if (advanceId === "advanceAnalysis") {
        setFilter(
          CountryOsBrowserQueryData(selectValue, platformValue, url_shortner_id)
        );
      } else if (advanceId === "advanceAnalysisDeepLink") {
        setFilter(
          DeepLinkOsBrowserQueryData(
            selectValue,
            platformValue,
            url_shortner_id
          )
        );
      }
    }
  }, [selectValue]);

  const handleChangeSelect = (event) => {
    if (event.target.value !== "dateRange") {
      setSelectDateRange([null, null]);
    }
    setSelect(event.target.value);
    if (advanceId === "advanceAnalysis") {
      setFilter(
        CountryOsBrowserQueryData(
          event.target.value,
          platformValue,
          url_shortner_id
        )
      );
    } else if (advanceId === "advanceAnalysisDeepLink") {
      setFilter(
        DeepLinkOsBrowserQueryData(
          event.target.value,
          platformValue,
          url_shortner_id
        )
      );
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (!setFilter) return;
    if (newValue === 0) {
      !!setFilter &&
        setFilter(
          advanceId === "advanceAnalysis"
            ? CountryOsBrowserQueryData(selectValue, "os", url_shortner_id)
            : DeepLinkOsBrowserQueryData(selectValue, "os", url_shortner_id)
        );
      setSelectDateRange([null, null]);
      setSelect(selectValue === "dateRange" ? "last7" : selectValue);
      !!setPlatformValue && setPlatformValue("os");
    } else if (newValue === 1) {
      !!setFilter &&
        setFilter(
          advanceId === "advanceAnalysis"
            ? CountryOsBrowserQueryData(selectValue, "country", url_shortner_id)
            : DeepLinkOsBrowserQueryData(
                selectValue,
                "country",
                url_shortner_id
              )
        );
      setSelectDateRange([null, null]);
      setSelect(selectValue === "dateRange" ? "last7" : selectValue);
      !!setPlatformValue && setPlatformValue("country");
    } else if (newValue === 2) {
      !!setFilter &&
        setFilter(
          advanceId === "advanceAnalysis"
            ? CountryOsBrowserQueryData(selectValue, "browser", url_shortner_id)
            : DeepLinkOsBrowserQueryData(
                selectValue,
                "browser",
                url_shortner_id
              )
        );
      setSelectDateRange([null, null]);
      setSelect(selectValue === "dateRange" ? "last7" : selectValue);
      !!setPlatformValue && setPlatformValue("browser");
    } else if (newValue === 3) {
      !!setFilter &&
        setFilter(
          advanceId === "advanceAnalysis"
            ? CountryOsBrowserQueryData(selectValue, "city", url_shortner_id)
            : DeepLinkOsBrowserQueryData(selectValue, "city", url_shortner_id)
        );
      setSelectDateRange([null, null]);
      setSelect(selectValue === "dateRange" ? "last7" : selectValue);
      !!setPlatformValue && setPlatformValue("city");
    } else if (newValue === 4) {
      !!setFilter &&
        setFilter(
          advanceId === "advanceAnalysis"
            ? CountryOsBrowserQueryData(
                selectValue,
                "referrer",
                url_shortner_id
              )
            : DeepLinkOsBrowserQueryData(
                selectValue,
                "referrer",
                url_shortner_id
              )
        );
      setSelectDateRange([null, null]);
      setSelect(selectValue === "dateRange" ? "last7" : selectValue);
      !!setPlatformValue && setPlatformValue("referrer");
    }
  };

  return (
    <MainCard content={false}>
      {mainTitle && (
        <Box sx={{ p: 3 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={{ xs: 0, xsm: 1 }}
            sx={{
              display: { xs: "grid", xsm: "flex" },
            }}
          >
            <Typography variant="h5">{mainTitle}</Typography>
            <FormControl
              sx={{
                minWidth: 150,
                display: { xs: "block", xsm: "flex" },
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Select
                value={selectValue}
                onChange={handleChangeSelect}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  marginTop: { xs: 1, xsm: 0 },
                  marginBottom: { xs: 1, xsm: 0 },
                }}
                onOpen={() => {
                  if (!selectDateRange[1] && !!selectDateRange[0]) {
                    setSelectDateRange([null, null]);
                    setSelect("last7");
                  }
                }}
                disabled={disabled}
              >
                <MenuItem value={"today"}>
                  <em>{t("Today")}</em>
                </MenuItem>
                <MenuItem value={"yesterday"}>{t("Yesterday")}</MenuItem>
                <MenuItem value={"last7"}>{t("Last7days")}</MenuItem>
                <MenuItem value={"last30"}>{t("Last30days")}</MenuItem>
                <MenuItem value={"thisMonth"}>{t("ThisMonth")}</MenuItem>
                <MenuItem value={"lastMonth"}>{t("LastMonth")}</MenuItem>
                {selectValue === "dateRange" && (
                  <MenuItem value={"dateRange"}>
                    {`${moment(selectDateRange[0]).format(
                      "yyyy-MM-DD"
                    )} - ${moment(selectDateRange[1]).format("yyyy-MM-DD")}`}
                  </MenuItem>
                )}
              </Select>
              <Box
                sx={{
                  display: "flex",
                  marginLeft: { xs: 0, xsm: 1 },
                  marginBottom: { xs: 1, xsm: 0 },
                }}
              >
                <DateRangePicker
                  selectDateRange={selectDateRange}
                  setSelectDateRange={setSelectDateRange}
                  setSelect={setSelect}
                  disabled={disabled}
                />
              </Box>
            </FormControl>
          </Stack>
        </Box>
      )}
      <Box
        sx={{ width: "100%", height: boxHeight, overflow: "scroll", ...bodySx }}
      >
        {showTabs && (
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              position: "sticky",
              top: "0",
              background: "white",
              zIndex: 2,
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={
                ({
                  px: 1,
                  pt: 3,
                  width: "100%",
                  overflow: "scroll",
                },
                { "& .MuiTabs-scroller ": { overflow: "scroll !important" } })
              }
              scrollButtons={true}
            >
              {tabList?.map((tab, i) => {
                return (
                  <Tab
                    sx={{ pb: 3 }}
                    key={i}
                    label={t(tab)}
                    {...a11yProps(i)}
                    disabled={disabled}
                  />
                );
              })}
            </Tabs>
          </Box>
        )}
        <AdvancedAnalysis
          value={value}
          filterValue={filterValue}
          advanceId={advanceId}
        />
      </Box>
    </MainCard>
  );
}
