import PropTypes from "prop-types";

// material-ui
import { Box, Skeleton, Typography } from "@mui/material";
import MainCard from "../../Component/MainCard";
import { useState } from "react";

// project-imports
// import MainCard from 'components/MainCard';

// ==============================|| STATISTICS - REPORT CARD ||============================== //

const EcommerceMetrix = ({
  content = "0",
  iconPrimary,
  color,
  title,
  isLoading = false,
  filpCard = false,
  content1,
  iconPrimary1,
  title1,
  color1,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleCardFlip = () => {
    if (filpCard) {
      setIsFlipped(!isFlipped);
    }
  };
  // const IconPrimary = iconPrimary;
  // const primaryIcon = iconPrimary ? (
  //   <IconPrimary size={52} variant="Bulk" />
  // ) : null;

  return (
    <MainCard
      content={false}
      sx={{
        bgcolor: color,
        position: "relative",
        backgroundColor: "transparent",
        border: "none",
        "&:before, &:after": {
          content: '""',
          width: 1,
          height: 1,
          position: "absolute",
          background:
            "linear-gradient(90deg, rgba(255, 255, 255, 0.0001) 22.07%, rgba(255, 255, 255, 0.15) 83.21%)",
          transform: "matrix(0.9, 0.44, -0.44, 0.9, 0, 0)",
          zIndex: 1,
        },
        "&:after": {
          top: "30%",
          right: "50px",
        },
        "&:before": {
          right: "-70px",
          bottom: "60%",
        },
        "&:hover svg": {
          opacity: 1,
          transform: "scale(1.1)",
        },
        "&:hover .flip-card-inner": {
          transform: filpCard ? "rotateY(180deg)" : "rotateY(0deg)",
        },
      }}
      onMouseEnter={() => handleCardFlip()}
      onMouseLeave={() => handleCardFlip()}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          minHeight: "122px",
          transition: "transform 0.9s",
          transformStyle: "preserve-3d",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          backgroundColor: "transparent",
        }}
        className={"flip-card-inner"}
      >
        <Box
          sx={{
            px: 4.5,
            py: 4,
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            backfaceVisibility: "hidden",
            position: "absolute",
            width: "100%",
            height: "100%",
            bgcolor: color,
            borderRadius: "8px",
          }}
        >
          <Box>
            {isLoading ? (
              <Skeleton variant="rectangular" width={100} height={30} />
            ) : (
              <Typography
                variant="h3"
                color="common.white"
                sx={{ fontWeight: 500 }}
              >
                {content}
              </Typography>
            )}
            <Typography
              variant="h5"
              color="common.white"
              sx={{ fontWeight: 400 }}
            >
              {title}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              "& svg": {
                opacity: 0.4,
                transition: "all .3s ease-in-out",
              },
            }}
          >
            {iconPrimary}
          </Box>
        </Box>
        <Box
          sx={{
            transform: "rotateY(180deg)",
            borderRadius: "8px",
            backgroundColor: color1,
            px: 4.5,
            py: 4,
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            backfaceVisibility: "hidden",
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        >
          <Box>
            {isLoading ? (
              <Skeleton variant="rectangular" width={100} height={30} />
            ) : (
              <Typography
                variant="h3"
                color="common.white"
                sx={{ fontWeight: 500 }}
              >
                {content1}
              </Typography>
            )}
            <Typography
              variant="h5"
              color="common.white"
              sx={{ fontWeight: 400 }}
            >
              {title1}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              "& svg": {
                opacity: 0.4,
                transition: "all .3s ease-in-out",
              },
            }}
          >
            {iconPrimary1}
          </Box>
        </Box>
      </Box>
    </MainCard>
  );
};

EcommerceMetrix.propTypes = {
  color: PropTypes.string,
  primary: PropTypes.string,
  secondary: PropTypes.string,
  content: PropTypes.string,
  iconPrimary: PropTypes.object,
};

export default EcommerceMetrix;
