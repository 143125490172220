import api from "../api/api";
import * as url from "../api/url";

// domain
export const createDomain = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.CREATE_DOMAIN, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const editDomain = (data, params) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.EDIT_DOMAIN + params, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const deleteDomain = (params) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(url.DELETE_DOMAIN + params)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getAllDomains = (data) => {
  if (data === undefined) {
    return new Promise(async (resolve, reject) => {
      return api
        .getWithToken(url.GET_ALL_DOMAINS)
        .then((response) => {
          if (response) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  }
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_ALL_DOMAINS + data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getAllDomainsCount = (data) => {
  if (data === undefined) {
    return new Promise(async (resolve, reject) => {
      return api
        .getWithToken(url.GET_ALL_DOMAINS_COUNT)
        .then((response) => {
          if (response) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  }
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_ALL_DOMAINS_COUNT + data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const getDomainListForURL = (data) => {
  if (data === undefined) {
    return new Promise(async (resolve, reject) => {
      return api
        .getWithToken(url.GET_SHORT_URL_DOMAINS)
        .then((response) => {
          if (response) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  }
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_SHORT_URL_DOMAINS + data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getStaticDomain = () => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.GET_STATIC_DOMAIN)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getDomainAllURL = (pagination) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_DOMAIN_URLS + pagination)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getDomainAllURLCount = (pagination) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_DOMAINS_URLS_COUNT + pagination)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getDashboardSingleUrl = (url_shorten_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(
        url.DASHBOARD_DATA_SIGNLE_URL + `?url_shortner_id=${url_shorten_id}`
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getSpaceUsedDomain = (query) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.SPACE_USED_DOMAIN + query)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const setPrimaryDomain = (params, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.PRIMARY_DOMAIN + params, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const testDomain = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.TEST_DOMAIN + id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getDomainDetailAPI = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.DOMAIN_DETAIL + id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
