import Reseller_Icon from "../assets/navigation/Reseller_Icon";
import CouponLicense_Icon from "../assets/navigation/CouponLicense_Icon";
import FormattedMessageCustomize from "./FormattedMessageCusttomize";

const coupon_key = {
  id: "COUPON AND KEY",
  title: <FormattedMessageCustomize id="Coupon&KeyGroup" />,
  icon: Reseller_Icon,
  type: "group",
  children: [
    {
      id: "Reseller",
      title: <FormattedMessageCustomize id="Reseller" />,
      type: "item",
      url: "/reseller",
      icon: Reseller_Icon,
      index: 15,
    },
    {
      id: "Coupon or License Key",
      title: <FormattedMessageCustomize id="CouponLicenseKey" />,
      type: "item",
      url: "/coupon",
      icon: CouponLicense_Icon,
      index: 16,
    },
    {
      id: "Reseller Features",
      title: <FormattedMessageCustomize id="ResellerFeatures" />,
      type: "subitem",
      url: "/reseller/resellerfeatures",
      icon: CouponLicense_Icon,
      parents: {
        id: "Reseller",
        title: <FormattedMessageCustomize id="Reseller" />,
        url: "/reseller",
      },
    },
  ],
};

export default coupon_key;
