import axios from "axios";
import { URL } from "./adminUrl";
import { STORAGEKEY } from "../Component/Constant";
export const Bearer = "Bearer ";

const instance = axios.create({
  baseURL: URL,
});

let retryAttempts = 0;

// Add a response interceptor
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error.response &&
      (error.response.status === 403 || error.response.status === 401)
    ) {
      localStorage.clear();
      window.location.href = "/auth/login";
    }

    if (error.code === "ERR_NETWORK") {
      if (retryAttempts < 1) {
        retryAttempts++;

        try {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          const originalRequest = error.config;
          return instance(originalRequest);
        } catch (retryError) {
          window.location.href = "/construction";
          return Promise.reject(retryError);
        }
      } else {
        window.location.href = "/construction";
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);
// axios.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     if (error.response.status === 401 || error.response.status === 403) {
//       localStorage.clear();
//       return window.location.assign("/auth/login");
//     } else {
//       return Promise.reject(error);
//     }
//   }
// );

// eslint-disable-next-line import/no-anonymous-default-export
// export default {
//   get: (url) => {
//     return axios.get(URL + url);
//   },

//   post: (url, data) => {
//     return axios.post(URL + url, data);
//   },

//   postWithToken: (url, data) => {
//     return axios({
//       method: "post",
//       data: data,
//       url: URL + url,
//       headers: {
//         authorization: Bearer + localStorage.getItem(STORAGEKEY.ACCESSTOKEN),
//       },
//     });
//   },

//   getWithParams: (url, params) => {
//     return axios({
//       method: "get",
//       params,
//       url: URL + url,
//     });
//   },

//   getWithToken: (url) => {
//     return axios({
//       method: "get",
//       url: URL + url,
//       headers: {
//         authorization: Bearer + localStorage.getItem(STORAGEKEY.ACCESSTOKEN),
//       },
//     });
//   },

//   putWithToken: (url, data) => {
//     return axios({
//       method: "put",
//       data: data,
//       url: URL + url,
//       headers: {
//         authorization: Bearer + localStorage.getItem(STORAGEKEY.ACCESSTOKEN),
//       },
//     });
//   },

//   deleteWithToken: (url, params) => {
//     return axios({
//       method: "delete",
//       params,
//       url: URL + url,
//       headers: {
//         authorization: Bearer + localStorage.getItem(STORAGEKEY.ACCESSTOKEN),
//       },
//     });
//   },
// };
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: (url) => {
    return instance.get(url);
  },

  post: (url, data) => {
    return instance.post(url, data);
  },

  put: (url, data) => {
    return instance.put(url, data);
  },

  postWithToken: (url, data) => {
    return instance.post(url, data, {
      headers: {
        authorization: Bearer + localStorage.getItem(STORAGEKEY.ACCESSTOKEN),
      },
    });
    // return axios({
    //   method: "post",
    //   data: data,
    //   url: URL + url,
    //   headers: {
    //     authorization: Bearer + localStorage.getItem(STORAGEKEY.ACCESSTOKEN),
    //   },
    // });
  },

  getWithParams: (url, params) => {
    return instance.get(url, {
      headers: {
        authorization: Bearer + localStorage.getItem(STORAGEKEY.ACCESSTOKEN),
      },
      params,
    });
    // return axios({
    //   method: "get",
    //   params,
    //   url: URL + url,
    // });
  },

  getWithToken: (url) => {
    return instance.get(url, {
      headers: {
        authorization: Bearer + localStorage.getItem(STORAGEKEY.ACCESSTOKEN),
      },
    });
    // return axios({
    //   method: "get",
    //   url: URL + url,
    //   headers: {
    //     authorization: Bearer + localStorage.getItem(STORAGEKEY.ACCESSTOKEN),
    //   },
    // });
  },

  getWithTokenTelegram: (url) => {
    return instance.get(url, {
      headers: {
        authorization: Bearer + localStorage.getItem(STORAGEKEY.ACCESSTOKEN),
      },
    });
    // return axios({
    //   method: "get",
    //   url: url,
    //   headers: {
    //     authorization: Bearer + localStorage.getItem(STORAGEKEY.ACCESSTOKEN),
    //   },
    // });
  },

  putWithToken: (url, data) => {
    return instance.put(url, data, {
      headers: {
        authorization: Bearer + localStorage.getItem(STORAGEKEY.ACCESSTOKEN),
      },
    });
    // return axios({
    //   method: "put",
    //   data: data,
    //   url: URL + url,
    //   headers: {
    //     authorization: Bearer + localStorage.getItem(STORAGEKEY.ACCESSTOKEN),
    //   },
    // });
  },

  deleteWithToken: (url, params) => {
    return instance.delete(url, {
      headers: {
        authorization: Bearer + localStorage.getItem(STORAGEKEY.ACCESSTOKEN),
      },
      params,
    });
    // return axios({
    //   method: "delete",
    //   params,
    //   url: URL + url,
    //   headers: {
    //     authorization: Bearer + localStorage.getItem(STORAGEKEY.ACCESSTOKEN),
    //   },
    // });
  },

  deleteWithTokenData: (url, data) => {
    return instance.delete(url, {
      data: data,
      headers: {
        authorization: Bearer + localStorage.getItem(STORAGEKEY.ACCESSTOKEN),
      },
    });
    // return axios({
    //   method: "delete",
    //   data: data,
    //   url: URL + url,
    //   headers: {
    //     authorization: Bearer + localStorage.getItem(STORAGEKEY.ACCESSTOKEN),
    //   },
    // });
  },
};
