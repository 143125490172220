import AllLinks_Icon from "../assets/navigation/AllLinks_Icon";
import DeletedLinks_Icon from "../assets/navigation/DeletedLinks_Icon";
import { PresentionChart } from "iconsax-react";
import FormattedMessageCustomize from "./FormattedMessageCusttomize";

// assets

// ==============================|| MENU ITEMS - FORMS & TABLES ||============================== //

const history = {
  id: "history",
  title: <FormattedMessageCustomize id="HistoryGroup" />,
  icon: AllLinks_Icon,
  type: "group",
  children: [
    {
      id: "All Links",
      title: <FormattedMessageCustomize id="AllLinks" />,
      type: "item",
      url: "/allLinks",
      icon: AllLinks_Icon,
      index: 12,
      // breadcrumbs: false,
    },
    {
      id: "Daily Analytics",
      title: <FormattedMessageCustomize id="DailyAnalytics" />,
      type: "item",
      url: "/dailyAnalytics",
      icon: PresentionChart,
      index: 13,
    },
    {
      id: "Stats",
      title: <FormattedMessageCustomize id="Stats" />,
      type: "subitem",
      url: "/dailyAnalytics/stats",
      icon: PresentionChart,
      parents: {
        id: "Daily Analytics",
        title: <FormattedMessageCustomize id="DailyAnalytics" />,
        url: "/dailyAnalytics",
      },
    },
    {
      id: "Deleted Links",
      title: <FormattedMessageCustomize id="DeletedLinks" />,
      type: "item",
      url: "/deletedLinks",
      icon: DeletedLinks_Icon,
      index: 14,
    },
    {
      id: "Stats",
      title: <FormattedMessageCustomize id="Stats" />,
      type: "subitem",
      url: "/allLinks/stats",
      icon: DeletedLinks_Icon,
      parents: {
        id: "All Links",
        title: <FormattedMessageCustomize id="AllLinks" />,
        url: "/allLinks",
      },
    },
    {
      id: "Edit Links",
      title: <FormattedMessageCustomize id="EditLinks" />,
      type: "subitem",
      url: "/allLinks/edit-link",
      icon: DeletedLinks_Icon,
      parents: {
        id: "All Links",
        title: <FormattedMessageCustomize id="AllLinks" />,
        url: "/allLinks",
      },
    },
  ],
};

export default history;
