import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useTheme } from "@mui/material/styles";
import {
  Badge,
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  Paper,
  Popper,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import ProfileTab from "./ProfileTab";
import Avatar from "../../../../../Component/@extended/Avatar";
import MainCard from "../../../../../Component/MainCard";
import Transitions from "../../../../../Component/@extended/Transitions";
import IconButton from "../../../../../Component/@extended/IconButton";
import { ThemeMode } from "../../../../../config";
import avatar1 from "../../../../../assets/images/users/avatar-thumb-1.png";
import { Profile, Logout } from "iconsax-react";
import { STORAGEKEY } from "../../../../../Component/Constant";
import { useAccount } from "../../../../../Hooks/Other/useAccount";
import { useTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
      sx={{ p: 1 }}
    >
      {value === index && children}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `profile-tab-${index}`,
    "aria-controls": `profile-tabpanel-${index}`,
  };
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const ProfilePage = () => {
  const { i18n, t } = useTranslation();
  const [profile, setProfile] = useState("");
  const [userData, setUserData] = useState({});
  const theme = useTheme();
  // const navigate = useNavigate();

  // const { logout, user } = useAuth();
  const handleLogout = () => {
    localStorage.clear();
    // navigate(``, {
    //   state: {
    //     from: "",
    //   },
    // });
    window.location.href = "/auth/login";
  };

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data, refetch } = useAccount();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setUserData(data);
    setProfile(data?.profile_image);
  }, [data]);

  let username = "";

  const role = localStorage.getItem(STORAGEKEY.ROLE);
  if (role === "user") {
    username = "User";
  }
  if (role === "admin") {
    username = "Denis Vithani";
  }
  if (userData?.firstname && userData?.lastname) {
    username = userData.firstname + " " + userData.lastname;
  }
  const [read] = useState(2);

  return (
    <Box sx={{ flexShrink: 0, ml: 1.5 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          borderRadius: 1,
          "&:hover": {
            bgcolor:
              theme.palette.mode === ThemeMode.DARK
                ? "secondary.light"
                : "secondary.lighter",
          },
          "&:focus-visible": {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2,
          },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {/* <Badge
          badgeContent={read}
          color="success"
          sx={{ "& .MuiBadge-badge": { top: 2, right: 4 } }}
        > */}
        {/* <Badge
          color="success"
          variant="dot"
          sx={{ "& .MuiBadge-badge": { top: 2, right: 4 } }}
        >
          <Avatar
            alt="profile user"
            src={profile !== null && profile !== "" ? profile : avatar1}
          />
        </Badge> */}
        <Badge
          color="success"
          variant="dot"
          sx={{
            position: "relative",
            "& .MuiBadge-badge": {
              position: "absolute",
              bottom: 0,
              backgroundColor: "success",
              // transparent
              border: "3px solid white",
              marginTop: "75%",
              marginRight: "5px",
              padding: "3px",
              borderRadius: "10px",
            },
          }}
        >
          <Avatar
            alt="profile user"
            src={profile !== null && profile !== "" ? profile : avatar1}
            size="34"
          />
        </Badge>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            type="grow"
            position="top-right"
            in={open}
            {...TransitionProps}
          >
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: 290,
                minWidth: 240,
                maxWidth: 290,
                [theme.breakpoints.down("md")]: {
                  maxWidth: 250,
                },
                borderRadius: 1.5,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} content={false}>
                  <CardContent sx={{ px: 2.5, pt: 3 }}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={10}>
                        <Stack
                          direction="row"
                          spacing={1.25}
                          alignItems="center"
                        >
                          <Avatar
                            alt="profile user"
                            src={
                              profile !== null && profile !== ""
                                ? profile
                                : avatar1
                            }
                          />
                          <Stack sx={{ overflow: "hidden" }}>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {username}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item xs={2}>
                        <Tooltip title={t("Logout")}>
                          <IconButton
                            size="large"
                            color="error"
                            sx={{ p: 1 }}
                            onClick={handleLogout}
                          >
                            <Logout variant="Bulk" />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </CardContent>

                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      variant="fullWidth"
                      value={value}
                      onChange={handleChange}
                      aria-label="profile tabs"
                    >
                      <Tab
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          textTransform: "capitalize",
                        }}
                        icon={
                          <Profile
                            size={18}
                            style={{ marginBottom: 0, marginRight: "10px" }}
                          />
                        }
                        label={t("MyProfile")}
                        {...a11yProps(0)}
                      />
                      {/* <Tab
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          textTransform: "capitalize",
                        }}
                        icon={
                          <Setting2
                            size={18}
                            style={{ marginBottom: 0, marginRight: "10px" }}
                          />
                        }
                        label="Setting"
                        {...a11yProps(1)}
                      />*/}
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0} dir={theme.direction}>
                    <ProfileTab
                      handleLogout={handleLogout}
                      handleClose={handleClose}
                      handleChangeLanguage={handleChangeLanguage}
                    />
                  </TabPanel>
                  {/* <TabPanel value={value} index={1} dir={theme.direction}>
                    <SettingTab />
                  </TabPanel> */}
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default ProfilePage;
