import React from "react";
import { useQuery } from "react-query";
import * as URL from "../../api/url";
import api from "../../api/api";

export const useDynamicCountryData = ({
  apiType,
  lastPath,
  day,
  auth,
  search,
  page,
  limit,
  platform,
  type,
  payload,
  extra = "",
  url_shortner_id = "",
  is_bio_link = "url_shortner_id",
}) => {
  const { data, error, refetch, isLoading, isFetching } = useQuery(
    [`dynamic-${platform}`],
    async () => {
      let baseUrl = apiType === "POST" ? api.postWithToken : api.getWithToken;
      let lastPart = !!url_shortner_id
        ? `${
            is_bio_link === "url_shortner_id"
              ? URL.USER_STATE_TODAY_GRAPH
              : is_bio_link === "Iconurl_shortner_id"
              ? URL.LINK_BIO_SINGLE_ICON_STATS
              : is_bio_link === "file_id"
              ? URL.GET_FILES_URL_TODAY_GRAPH
              : URL.LINK_BIO_SINGLE_LINK_STATS
          }${lastPath}?${
            !!url_shortner_id ? `${is_bio_link}=${url_shortner_id}` : ""
          }${!!day ? `&day=${day}` : ""}${!!auth ? `&auth=${auth}` : ""}${
            !!search ? `&search=${encodeURIComponent(search)}` : ""
          }${!!page ? `&page=${page}` : ""}${!!limit ? `&limit=${limit}` : ""}${
            !!platform ? `&platform=${platform}` : ""
          }${!!type ? `&type=${type}` : ""}${extra}`
        : `${
            URL.DASHBOARD_TODAY_STATE_GRAPH
          }${lastPath}?day=${day}&auth=${auth}&search=${encodeURIComponent(
            search
          )}&page=${page}&limit=${limit}&platform=${platform}&type=${type}${extra}`;
      try {
        const response = await baseUrl(lastPart, payload);
        return response.data.data.data;
      } catch (error) {
        throw new Error("Failed to fetch account gateways");
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      retry: 0,
      staleTime: 0,
    }
  );

  return { data, error, refetch, isLoading, isFetching };
};
