import * as URL from "../../api/url";
import api from "../../api/api";
import { useQuery } from "react-query";

export const useDashboardCard = () => {
  const { data, error, refetch, isLoading, isFetching } = useQuery(
    ["dashboard-card"],
    async () => {
      try {
        const response = await api.getWithToken(URL.DASHBOARD_DATA);
        return response.data.data.data;
      } catch (error) {
        throw new Error("Failed to fetch account gateways");
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0,
    }
  );

  return { data, error, refetch, isLoading, isFetching };
};

export const useDashboardDataPopular = (latest_url) => {
  const { data, error, refetch, isLoading, isFetching } = useQuery(
    ["popular-last"],
    async () => {
      try {
        const response = await api.getWithToken(
          URL.DASHBOARD_DATA_POPULAR_LEAST + `?latest_url=${latest_url}`
        );
        return response.data.data;
      } catch (error) {
        throw new Error("Failed to fetch account gateways");
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0,
    }
  );

  return { data, error, refetch, isLoading, isFetching };
};

export const useDashboardSingleUrl = (url_shortner_id) => {
  const { data, error, refetch, isLoading, isFetching } = useQuery(
    ["single-url", url_shortner_id],
    async () => {
      try {
        const response = await api.getWithToken(
          URL.DASHBOARD_DATA_SIGNLE_URL + `?url_shortner_id=${url_shortner_id}`
        );
        return response.data.data;
      } catch (error) {
        throw new Error("Failed to fetch account gateways");
      }
    },
    {
      enabled: false, // Enable query only if url_shorten_id is defined
      cacheTime: 0, // Don't cache data
      retry: 0, // Don't automatically retry
      staleTime: 0,
    }
  );

  return { data, error, refetch, isLoading, isFetching };
};
