import PropTypes from "prop-types";

// material-ui
import { Typography, Stack, CardMedia } from "@mui/material";

// assets
import { Camera } from "iconsax-react";
import UploadCover from "../../assets/images/upload/upload-folder.svg";
import { DropzopType } from "../../config";
import { useTranslation } from "react-i18next";

// ==============================|| UPLOAD - PLACEHOLDER ||============================== //

export default function PlaceholderContent({ type, subTitle }) {
  const { t } = useTranslation();

  return (
    <>
      {type !== DropzopType.standard && (
        <Stack
          spacing={2}
          alignItems="center"
          justifyContent="center"
          // direction={{ xs: "column", md: "row" }}
          sx={{ textAlign: "center" }}
        >
          <CardMedia component="img" image={UploadCover} sx={{ width: 80 }} />
          <Stack sx={{ p: 1, marginTop: "10px !important" }} spacing={1}>
            <Typography variant="h5">{t("DragDropOrSelectFile")}</Typography>

            <Typography color="secondary">
              {subTitle}
              {/* Drop files here or click&nbsp;
              <Typography
                component="span"
                color="primary"
                sx={{ textDecoration: "underline" }}
              >
                browse
              </Typography>
              &nbsp;thorough your machine */}
            </Typography>
          </Stack>
        </Stack>
      )}
      {type === DropzopType.standard && (
        <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
          <Camera style={{ fontSize: "32px" }} />
        </Stack>
      )}
    </>
  );
}

PlaceholderContent.propTypes = {
  type: PropTypes.string,
};
