import { useEffect, useRef, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  ClickAwayListener,
  Paper,
  Popper,
  Toolbar,
} from "@mui/material";

// project-imports
import Search from "./Search";
import Profile from "./Profile";
import Localization from "./Localization";
import IconButton from "../../../../Component/@extended/IconButton";
import Transitions from "../../../../Component/@extended/Transitions";
import { ThemeMode } from "../../../../config";

// assets
import { MoreSquare } from "iconsax-react";
import { getChromeAddOnDataApi } from "../../../../services/UserConfigService";
import { Icon } from "@iconify/react";
import { Book } from "iconsax-react";
import { GetHelpCenterConfig } from "../../../../services/AdminService/configService";
import { useCurrentPackage } from "../../../../Hooks/Auth/useCurrentPackage";
import UsagePlanDetails from "./Profile/UsagePlanDetails";

// ==============================|| HEADER CONTENT - MOBILE ||============================== //

const MobileSection = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [supportUrl, setSupportUrl] = useState(null);
  const [displayLink, setDisplayLink] = useState(0);

  const { data: ActivePackages, refetch: refetchActivePackage } =
    useCurrentPackage();

  useEffect(() => {
    refetchActivePackage();
  }, []);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const iconBackColorOpen =
    theme.palette.mode === ThemeMode.DARK ? "secondary.200" : "secondary.200";
  const iconBackColor =
    theme.palette.mode === ThemeMode.DARK
      ? "background.default"
      : "secondary.100";

  const [addOnData, setAddOnData] = useState({});
  useEffect(() => {
    getChromeAddOnDataApi()
      .then((res) => {
        const data = res && res.data && res.data.data;
        setAddOnData(data);
      })
      .catch((err) => {
        setAddOnData({});
      });
    GetHelpCenterConfig().then((res) => {
      const resData = res && res.data && res.data.data && res.data.data.data;
      setSupportUrl(resData.help_center_url);
      setDisplayLink(resData);
    });
  }, []);

  const callHelpCenter = () => {
    if (!!supportUrl) {
      window.open(supportUrl, "_blank", "noreferrer");
    }
  };

  return (
    <>
      <Box sx={{ flexShrink: 0, ml: 0.75 }}>
        <IconButton
          aria-label="open more menu"
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          color="secondary"
          variant="light"
          size="large"
          sx={{
            color: "secondary.main",
            bgcolor: open ? iconBackColorOpen : iconBackColor,
            p: 1,
          }}
        >
          <MoreSquare
            size={28}
            variant="Bulk"
            style={{ transform: "rotate(90deg)" }}
          />
        </IconButton>
      </Box>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{ width: "100%" }}
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper sx={{ boxShadow: theme.customShadows.z1 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <AppBar color="inherit">
                  <Toolbar>
                    <Search />
                    <UsagePlanDetails />
                    {addOnData?.addOnAndroidAppData?.status === 1 ? (
                      ActivePackages?.add_on ? (
                        <a
                          href={
                            addOnData &&
                            addOnData?.addOnAndroidAppData?.download_link
                          }
                          target="_blank"
                          rel="noreferrer"
                          // style={{ height: "34px", marginRight: "10px" }}
                        >
                          <IconButton
                            color="secondary"
                            variant="light"
                            aria-label="settings toggler"
                            size="large"
                            sx={{
                              p: 1,
                              ml: 1,
                            }}
                          >
                            <Icon
                              icon="devicon:android"
                              height={34}
                              width={34}
                              style={{ cursor: "pointer" }}
                            />
                          </IconButton>
                        </a>
                      ) : (
                        <IconButton
                          color="secondary"
                          variant="light"
                          aria-label="settings toggler"
                          size="large"
                          sx={{
                            p: 1,
                            ml: 1,
                            cursor: "default",
                          }}
                        >
                          <Icon
                            icon="devicon-plain:android"
                            height={34}
                            width={34}
                            style={{ cursor: "not-allowed" }}
                          />
                        </IconButton>
                      )
                    ) : (
                      <></>
                    )}
                    {addOnData?.addonIosAppData?.status === 1 ? (
                      ActivePackages?.add_on ? (
                        <a
                          href={
                            addOnData &&
                            addOnData?.addonIosAppData?.download_link
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          <IconButton
                            color="secondary"
                            variant="light"
                            aria-label="settings toggler"
                            size="large"
                            sx={{
                              p: 1,
                              ml: 1,
                            }}
                          >
                            <Icon
                              icon="logos:apple"
                              height={34}
                              width={34}
                              style={{ cursor: "pointer" }}
                            />
                          </IconButton>
                        </a>
                      ) : (
                        <IconButton
                          color="secondary"
                          variant="light"
                          aria-label="settings toggler"
                          size="large"
                          sx={{
                            p: 1,
                            ml: 1,
                            cursor: "default",
                          }}
                        >
                          <Icon
                            icon="fa6-brands:apple"
                            height={34}
                            width={34}
                            style={{ cursor: "default" }}
                          />
                        </IconButton>
                      )
                    ) : (
                      <></>
                    )}
                    {addOnData?.mozillaExtension?.status === 1 ? (
                      ActivePackages?.add_on ? (
                        <a
                          href={
                            addOnData &&
                            addOnData?.mozillaExtension?.download_link
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          <IconButton
                            color="secondary"
                            variant="light"
                            aria-label="settings toggler"
                            size="large"
                            sx={{
                              p: 1,
                              ml: 1,
                            }}
                          >
                            <Icon
                              icon="logos:firefox"
                              height={34}
                              width={34}
                              style={{ cursor: "pointer" }}
                            />
                          </IconButton>
                        </a>
                      ) : (
                        <IconButton
                          color="secondary"
                          variant="light"
                          aria-label="settings toggler"
                          size="large"
                          sx={{
                            p: 1,
                            ml: 1,
                            cursor: "default",
                          }}
                        >
                          <Icon
                            icon="mdi:firefox"
                            height={34}
                            width={34}
                            style={{ cursor: "not-allowed" }}
                          />
                        </IconButton>
                      )
                    ) : (
                      <></>
                    )}
                    {addOnData?.chromeExtension?.status === 1 ? (
                      ActivePackages?.add_on ? (
                        <a
                          href={
                            addOnData &&
                            addOnData?.chromeExtension?.download_link
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          <IconButton
                            color="secondary"
                            variant="light"
                            aria-label="settings toggler"
                            size="large"
                            sx={{
                              p: 1,
                              ml: 1,
                            }}
                          >
                            <Icon
                              icon="logos:chrome"
                              height={34}
                              width={34}
                              style={{ cursor: "pointer" }}
                            />
                          </IconButton>
                        </a>
                      ) : (
                        // Render a placeholder or a disabled state
                        <IconButton
                          color="secondary"
                          variant="light"
                          aria-label="settings toggler"
                          size="large"
                          sx={{
                            p: 1,
                            ml: 1,
                            cursor: "default",
                          }}
                        >
                          <Icon
                            icon="devicon-plain:chrome"
                            height={34}
                            width={34}
                            style={{ cursor: "not-allowed" }}
                          />
                        </IconButton>
                      )
                    ) : (
                      <></>
                    )}
                    {displayLink?.status !== 0 ? (
                      <IconButton
                        color="secondary"
                        variant="light"
                        onClick={callHelpCenter}
                        aria-label="settings toggler"
                        size="large"
                        sx={{
                          color: "secondary.main",
                          p: 1,
                          ml: 2,
                        }}
                      >
                        <Icon
                          icon={"emojione-v1:books"}
                          height={34}
                          width={34}
                          style={{ cursor: "pointer" }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        color="secondary"
                        variant="light"
                        aria-label="settings toggler"
                        size="large"
                        sx={{
                          p: 1,
                          ml: 1,
                          height: "50px",
                          width: "50px",
                          cursor: "default",
                        }}
                      >
                        <Icon
                          icon={"emojione-monotone:books"}
                          height={34}
                          width={34}
                          style={{ cursor: "default" }}
                        />
                      </IconButton>
                    )}
                    {/* <Localization /> */}
                    <Profile />
                  </Toolbar>
                </AppBar>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default MobileSection;
