import { Box, Grid, TextField, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import EcommerceMetrix from "../Dashboard/EcommerceMetrix";
import Transactions from "../Dashboard/Transactions";
import Clicks from "../../assets/Dashboard/Clicks";
import TodayClicks from "../../assets/Dashboard/TodayClicks";
import CreatedAt from "../../assets/links/CreatedAt";
import { constants as CONSTS } from "../../Constants/constants";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetLinkTotalCount,
  useTodaysClicks,
} from "../../Hooks/links/useStatsCard";
import moment from "moment/moment";
import { Col } from "reactstrap";
import LinksChart from "../Dashboard/LinksChart";
import DonutChart from "../Dashboard/DonutChart";
import { useCurrentPackage } from "../../Hooks/Auth/useCurrentPackage";
import { advanceTabList } from "../../Helpers/common";
import { useDynamicCountryData } from "../../Hooks/Dashboard/useDynamicCountryData";
import { useTranslation } from "react-i18next";

const Stats = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  let urlData = location.state;
  const { data: ActivePackages, refetch: refetchActivePackage } =
    useCurrentPackage();
  const [platformValue, setPlatformValue] = useState("os");
  const [filterValue, setFilter] = useState({
    apiType: "POST", // apiType ,
    lastPath: `/lastdays`, // lastPath /lastdays || /month || /dynamic_dates,
    day: "", // day today||yesterday,
    auth: "web", // auth
    search: "", // search,
    page: 1, // page,
    limit: "", // limit,
    platform: platformValue, // platform country ||os ||browser,
    type: "click", // type,
    payload: { days: 7 || 30 }, // payload  {days: 7 || 30}  {start_date: "2023-11-08", end_date: "2023-11-16"}}
    url_shortner_id: urlData?.url_shortner_id,
  });
  const { data: dynamicState } = useDynamicCountryData(filterValue);

  const {
    data: todayClickData,
    refetch: todayClickRefetch,
    isFetching,
    isLoading,
  } = useTodaysClicks({
    url_shortner_id: urlData?.url_shortner_id,
    date: { selectDay: "today" },
  });

  const {
    data: totalLinkCount,
    refetch: refetchLinkTotalCount,
    isLoading: LinkLoading,
    isFetching: LinkFetching,
  } = useGetLinkTotalCount(urlData?.url_shortner_id);

  useEffect(() => {
    if (!urlData) {
      navigate(-1);
    }
    refetchActivePackage();
  }, []);

  useEffect(() => {
    todayClickRefetch();
    refetchLinkTotalCount();
  }, [urlData]);

  return (
    <>
      <Grid container rowSpacing={3} columnSpacing={3}>
        <Grid item xs={12}>
          <Box>
            <TextField
              fullWidth
              value={urlData?.short_url || ""}
              disabled
              sx={{
                "& .Mui-disabled": {
                  " -webkit-text-fill-color": "black !important",
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} xl={3}>
          <Grid container columnSpacing={3}>
            <Grid item sm={6} xs={12} xl={12} md={4}>
              <Box sx={{ pb: { xl: 3, md: 0, xs: 3 } }}>
                <EcommerceMetrix
                  title={t(CONSTS.TOTAL_CLICKS)}
                  content={`${totalLinkCount?.totalClicks || 0}`}
                  color={theme.palette.primary.main}
                  iconPrimary={<Clicks />}
                  isLoading={LinkFetching || LinkLoading}
                />
              </Box>
            </Grid>
            <Grid item sm={6} xs={12} xl={12} md={4}>
              <Box sx={{ pb: { xl: 3, md: 0, xs: 3 } }}>
                <EcommerceMetrix
                  title={t(CONSTS.TODAYS_CLICKS)}
                  content={`${totalLinkCount?.todayClicks || 0}`}
                  color={"#7265E6"}
                  iconPrimary={<TodayClicks />}
                  isLoading={LinkFetching || LinkLoading}
                />
              </Box>
            </Grid>
            <Grid item sm={6} xs={12} xl={12} md={4}>
              <Box>
                <EcommerceMetrix
                  title={t(CONSTS.CREATED_AT)}
                  content={moment(totalLinkCount?.createdAt).format(
                    "YYYY-MM-DD"
                  )}
                  color={"#B53874"}
                  iconPrimary={<CreatedAt />}
                  isLoading={LinkFetching || LinkLoading}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} xl={9}>
          <Col lg={12} md={12} sm={12}>
            <LinksChart
              chartHeight={300}
              url_shortner_id={urlData?.url_shortner_id}
              is_rotator={urlData?.is_rotator}
            />
          </Col>
        </Grid>
        {ActivePackages?.advance_statistics && (
          <>
            <Grid item xs={12} lg={6}>
              <Transactions
                tabList={advanceTabList}
                mainTitle={t("AdvancedAnalytics")}
                boxHeight="400px"
                showTabs={true}
                filterValue={filterValue}
                setFilter={setFilter}
                platformValue={platformValue}
                setPlatformValue={setPlatformValue}
                url_shortner_id={urlData?.url_shortner_id}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <DonutChart
                dynamicState={dynamicState}
                platformValue={platformValue}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Stats;
