export const DevURL = `${process.env.REACT_APP_BACKEND_HOST}/api/user/`;
// export const DevURL = "https://192.168.0.181:7000/api/user/";
// export const DevURL = "https://localhost:7000/api/user/";

// ? Authentication URL
export const LOGIN_URL = "login";
export const REGISTER_URL = "signup";
export const FORGOT_PASSWORD = "forgot_password/email/";
export const RESET_PASSWORD = "change_password_otp";
export const DELETE_USER_ACCOUNT = "me";
export const VALID_TOKEN = "valid_token";
export const FACEBOOK_SET_PASSWORD = "submitPassword";
export const GOOGLE_SET_PASSWORD = "setpassword";
export const LOGIN_WITH_GOOGLE = "auth/google";
export const LOGIN_WITH_FACEBOOK = "auth/facebook";

// ? shorten url
export const ADD_LONG_URL = "url_shortner";
export const EDIT_LONG_URL = "url_shortner";
export const DELETE_LONG_URL = "url_shortner";
export const GET_SHORT_URL = "url_shortner";
export const GET_SHORT_URL_RESULT = "url_shortner/get_links_data";
export const GET_SHORT_URL_CLICK = "url_shortner/get_link_click";
export const GET_POPULAR_LINKS = "url_shortner/get_url";
export const GET_ALL_POPULAR_CLICKS =
  "url_shortner/get_all_links_popular_click";
export const GET_TODAY_CLICK_LINK = "url_shortner/today_click_link";
export const GET_TODAY_CLICK_LINK_COUNT =
  "url_shortner/todays_links_click_count";
export const GET_SHORT_URL_RESULT_COUNT = "url_shortner/get_links_count";
export const GET_POPULAR_LINKS_COUNT =
  "url_shortner/get_all_links_popular_count";
export const RESET_STATS_LINK = "url_shortner/delete_link_clicks";
export const GET_SHORT_URL_PREVIEW = "url_shortner/preview";
export const GET_SHORT_URL_DETAILS = "url_shortner/get";
export const GET_SHORT_URL_DOMAINS = "url_shortner/domain_list";
export const GET_SHORT_URL_UTM_RETARGET = "config/getuserutmretarget";
export const DELETE_UTM_RETARGET = "config/deleteRetarget";
export const UPDATE_UTM_RETARGET = "config/updatedomain";
export const GET_URL_ROTATING_CONFIG = "url_shortner/config/url_rotator";
export const GET_DELETED_URL_DETAILS = "url_shortner/deletedurls";
export const GET_DELETED_URL_DETAILS_COUNT = "url_shortner/deletedurls_count";

// ? Link Cloaking
export const GET_LINK_CLOAKING = "url_shortner/isiframable";
export const GET_LINK_CLOAKING_LINKS = "url_shortner/cloakingurls";
export const GET_LINK_CLOAKING_COUNT = "url_shortner/cloakingurlcounts";
export const GET_LINK_CLOAKING_RESULT = "url_shortner/cloakingdata";
export const GET_POPULAR_LINK_CLOAKING_CLICKS =
  "url_shortner/popularcloaklinks";
export const GET_POPULAR_LINK_CLOAKING_COUNT = "url_shortner/popular_counts";

// ? All Links
export const GET_ALL_LINKS = "url_shortner/get_all_links";
export const GET_ALL_LINKS_COUNT = "url_shortner/get_all_links_count";

// ? create space
export const CREATE_SPACE = "space";
export const EDIT_SPACE = "space?space_id=";
export const DELETE_SPACE = "space?space_id=";
export const GET_ALL_SPACE = "space";
export const GET_ALL_SPACE_COUNT = "space/space_count";
export const GET_SPACE_URLS = "space/urls?space_id=";
export const GET_SPACE_URLS_COUNT = "space/urls_count?space_id=";
export const GET_SPACE_DETAIL = "space/detail";
export const MOVE_SPACE = "space/move_url?space_id=";
export const TRANSFER_DOMAIN = "space/transfer_domain?all_domain=";
export const SPACE_USED_DOMAIN = "space/used_domain?space_id=";
export const SPACE_ANALYTICS = "dashboard/file_statistics";
export const SPACE_ANALYTICS_COUNT = "dashboard/space_bulk_count";
export const LINK_ANALYTICS_COUNT = "dashboard/url_shortner_count";
export const SPACE_STATE_GRAPH = "url_shortner/statistics/file_platforms";

// ? domain
export const CREATE_DOMAIN = "domain";
export const EDIT_DOMAIN = "domain?domain_id=";
export const DELETE_DOMAIN = "domain?domain_id=";
export const GET_ALL_DOMAINS = "domain";
export const GET_ALL_DOMAINS_COUNT = "domain/domain_count";
export const GET_DOMAIN_URLS = "domain/details";
export const GET_DOMAINS_URLS_COUNT = "domain/details_count";
export const GET_STATIC_DOMAIN = "config/static_domain";
export const PRIMARY_DOMAIN = "domain/primary?domain_id=";
export const TEST_DOMAIN = "domain/test?domain_id=";
export const DOMAIN_DETAIL = "domain/details?domain_id=";

// ? dashboard
export const DASHBOARD_DATA = "dashboard";
export const DASHBOARD_DATA_POPULAR_LEAST = "dashboard/links_data";
export const DASHBOARD_DATA_SIGNLE_URL = "dashboard/get_single_url";
export const USER_TODAY_GRAPH = "dashboard/statistics";
export const USER_LASTDAYS_GRAPH = "dashboard/statistics/lastdays";
export const USER_MONTH_GRAPH = "dashboard/statistics/month";
export const USER_CUSTOM_GRAPH = "dashboard/statistics/dynamic_dates";
export const DASHBOARD_QR_CODE = "url_shortner/qrcode";
export const DEEP_LINKING_QR_CODE = "deep_link/qrcode";
export const DASHBOARD_TODAY_STATE_GRAPH = "url_shortner/statistics/platforms";
export const DASHBOARD_LASTDAY_STATE_GRAPH =
  "url_shortner/statistics/platforms/lastdays";
export const DASHBOARD_MONTH_STATE_GRAPH =
  "url_shortner/statistics/platforms/month";
export const DASHBOARD_DYNAMIC_STATE_GRAPH =
  "url_shortner/statistics/platforms/dynamic_dates";

// ? Single URL Stats
export const USER_URL_TODAY_GRAPH = "url_shortner/statistics";
export const USER_URL_LASTDAYS_GRAPH = "url_shortner/statistics/lastdays";
export const USER_URL_MONTH_GRAPH = "url_shortner/statistics/month";
export const USER_URL_CUSTOM_GRAPH = "url_shortner/statistics/dynamic_dates";
export const USER_STATE_TODAY_GRAPH =
  "url_shortner/statistics/single/platforms";
export const USER_STATE_LASTDAY_GRAPH =
  "url_shortner/statistics/single/platforms/lastdays";
export const USER_STATE_MONTH_GRAPH =
  "url_shortner/statistics/single/platforms/month";
export const USER_STATE_DYNAMIC_GRAPH =
  "url_shortner/statistics/single/platforms/dynamic_dates";
export const USER_ROTATING_STATE_LASTDAY_GRAPH =
  "url_shortner/statistics/single/platforms/rotating/lastdays";
export const USER_ROTATING_STATE_TODAY_GRAPH =
  "url_shortner/statistics/single/platforms/rotating";
export const USER_ROTATING_STATE_MONTH_GRAPH =
  "url_shortner/statistics/single/platforms/rotating/month";
export const USER_ROTATING_STATE_DYNAMIC_GRAPH =
  "url_shortner/statistics/single/platforms/rotating/dynamic_dates";

// ? Bunch URLs
export const BUNCH_SHORTNER_URLS = "url_shortner";
export const BUNCH_URLS = "url_shortner/bulk";
export const URL_DATA = "url_shortner/get?url_shortner_id=";
export const BUNCHS_URLS_COUNT = "url_shortner/get_all_bunches_count";
export const DOWNLOAD_DEMO_CSV = "url_shortner/demo-file";
export const DOWNLOAD_ALIAS_DEMO_CSV = "url_shortner/alias-demo-file";
export const DOWNLOAD_TAGS_DEMO_CSV = "url_shortner/alias-demo-tags-file";
export const BUNCHS_ALL_URLS = "url_shortner/add_bulk";
export const BUNCHS_ALL_URLS_COUNT = "url_shortner/get_all_bunches_url_count";
export const BUNCHS_DETAILS = "url_shortner/bunch/details";
export const BUNCHES_STATUS_EDIT = "url_shortner/update_bunches_status";

// ? account
export const UPDATE_USER_ACCOUNT = "me";
export const VERIFICATION = "verification";
export const RESEND_CODE = "resend_verification";
export const PROFILE_IMAGE = "profile";
export const USER_MAIL_CHANGE = "user_mail_change";
export const USER_MAIL_VERIFY_OTP = "set_new_mail";
export const USER_MAIL_RESEND_VERIFY_OTP = "resendcode";
export const GET_USER_ACCOUNT = "me";
export const PACKAGE_HISTORY = "active_package/list";
export const PACKAGE_HISTORY_COUNT = "active_package/list_count";
export const GET_BUY_PACKAGE_CONFIG = "config/buy_package";
export const CURRENT_PACKAGE = "active_package";
export const APPLY_COUPON = "coupon/apply";
export const RESELLER_COUPON = "coupon";
export const RESELLER_FEATURES = "active_package/plan_detail?id=2";
export const RESELLER_COUPON_COUNT = "coupon/reseller_coupon_count";
export const GENRATE_KEY = "v1";
export const GET_KEY = "v1";
export const UPDATE_USER_ONBOARDING = "update_user_onbording";
export const UPDATE_SUB_USER_STATUS = "update_subuser_status";

//? Files
export const GET_ALL_FILES = "files/get_all_files_url";
export const GET_ALL_FILES_URLS = "files/get_file_url_data";
export const DELETE_FILE_LINK = "files/delete_file_link";
export const DELETE_FILE = "files/delete_file";
export const EDIT_FILE = "files/update_file";
export const ADD_FILES = "files/add_file";
export const ADD_NEW_FILES = "files/add_new_files";
export const DOWNLOAD_FILE_COUNT = "download_file_count";
export const GET_FILE_DETAILS = "get_file_detail";
export const GET_FILE_URLS = "url_shortner/statistics/totalclicksfileurl";
export const GET_FILES_URL_TODAY_GRAPH =
  "/url_shortner/statistics/single/platform";

//? Import Link
export const ADD_MIGRATING_URL = "url_shortner/short_migrating_url";
export const GET_MIGRATING_URL = "url_shortner/migrate_data";
export const DELETE_MIGRATING_URL = "url_shortner/delete_migrate_url";
export const GET_MIGRATING_DETAIL_URL = "url_shortner/migrate_url_data";
export const GET_MIGRATING_DETAIL_COUNT = "url_shortner/migrate_url_count";

//? user-config
export const EXPIRE_LINK = "config/link_expire";
export const DELETE_LINK = "config/delete_link";
export const ADD_RETARGETING_UTM_SETTING = "config/addretargeting";
export const GET_CONFIG_SETTING = "config/url_shortner";
export const GET_ADD_ON = "config/add_on";
export const GET_CHROME_ADD_ON = "config/chrome_add_on";
export const DELETE_CLICK_DAYS = "update_click_delete_limit";
export const GET_ALERTED_ORIGINAL_LINK_DOWN = "telegram/url_down_notification";
export const GET_PASSWORD_LINK = "config/getlogo";
export const ADD_PASSWORD_LINK = "config/addlogo";
export const GET_WITHOUT_AUTH_PASSWORD_LINK = "config/getpasswordpage";
export const RESET_PASSWORD_LINK = "config/setdefault";

export const ADD_ON_PABBLY = "config/add_on/pabbly";

// ? Package
export const GET_ALL_PACKAGES = "package";
export const BUY_PACKAGE = "package/buy";
export const APPLY_PROMO_CODE = "package/apply_promo_code";
export const PURCHASED_ADD_ON_DATA = "package/purchased_add_on";

// ? Telegram Notification
export const SET_UP_TELEGRAM = `api/telegram/set_chat_id`;
export const TELEGRAM_NOTIFICATION = "telegram/notification";
//? Pabbly
export const GET_ALL_PABBLY_PACKAGES = "pabbly";
export const APPLY_PABBLY_PROMO_CODE = "pabbly/apply_promo_code";
export const BUY_PABBLY_PACKAGE = "pabbly/buy";

export const FREE_PLAN = "default_package";

export const URL = DevURL;

//? Link Bio
// https://dev-fox.foxlyme.com/api/user/linkBio/deleteAll?short_code=undefined

export const PROFILE = "linkBio/profile";
export const GET_SINGLE_PROFILE = "linkBio/get_link_bio_detail";
export const ADD_USER_APPEARANCE = "linkBio/appearance";
export const ADD_BIO_LINK = "linkBio/link";
export const EDIT_SUBSCRIBE = "linkBio/update_link_bio_subscribe_status";
export const EDIT_SUBSCRIBE_MESSAGE =
  "linkBio/update_link_bio_subscribe_message";
export const GET_SUBSCRIBE_LIST = "linkBio/get_subscribe_data";
export const CHANGE_LINK_STATUS = "linkBio/status";
export const GET_USER_ALL_LINKS = "linkBio/links";
export const GET_LINK_PREVIEW = "linkBio/preview";
export const REORDER_LIST = "linkBio/reorder";
export const GEN_QR = "linkBio/qr";
export const TOTAL_COUNT = "linkBio/clicks";
export const GET_METADATA = "linkBio/metadata";
export const DYNAMIC_STATISTICS = "linkBio/statistics/dynamic";
export const GET_ICON_DYNAMIC_STATISTICS = "link_bio_icon/geticonurldynamic";
export const COLOR_CODE = "color";
export const DELETE_BIOLINK = "linkBio/deleteAll";
export const EDIT_BIOLINK_STATUS = "linkBio/update_bio_link_status";
export const BIOLINK_THEME = "color/theme";
export const ADD_RESELLER_COUPON = "coupon";
export const GET_RESELLER_COUPON = "coupon";
export const ADD_BIO_LINK_ICON = "link_bio_icon";
export const ADD_BIO_LINK_CUSTOM_ICON = "link_bio_icon/add_cutome_icon";
export const EDIT_BIO_LINK_ICON = "link_bio_icon/update_icon";
export const REORDER_LIST_ICON = "link_bio_icon/reorder_icon";
export const GET_ICON_LIST = "link_bio_icon/get_link_bio_icon";
export const GET_ICON_DATA = "link_bio_icon/iconclicks";
export const EDIT_ICON_POSITION = "linkBio/update_social_icon_position";
export const CHANGE_ICON_STATUS = "link_bio_icon/update_icon_status";
export const LINK_BIO_SINGLE_LINK_STATS =
  "url_shortner/statistics/single/linkbiosplatforms";
export const LINK_BIO_SINGLE_ICON_STATS = "url_shortner/statistics/single";
// appSumo
export const APP_SUMO = "app_sumo_rating";

// plan details
export const PLAN_DETAIL = "active_package/plan_detail?id=";
export const PLAN_DETAIL_DATA = "active_package/plan_detail_data?id=";
export const SUBSCRIPTION = "subscribe_package";
export const UNSUBSCRIPTION = "unsubscribe_package";
export const UNSUBSCRIPTIONLIFETIME = "default_package";
export const SUBSCRIPTIONDETAILS = "subscription_details";
export const GET_USAGE_PLAN_DETAILS = "dashboard/Usage";

//deep Linking
export const GET_ALL_DEEP_LINKING_APP = "deeplink_app?app_type=";
export const GET_TYPE_ALL_DEEP_LINKING_APP = "deeplink_app_type?app_type=";
export const GET_DEEP_LINKING_APP = "deeplink_app?deep_link_app_id=";
export const GET_DEEP_LINKING_APP_DATA = "deep_link/app_data?deep_link_app_id=";
export const GET_ALL_DEEP_LINKING = "deep_link/get_all_deep_links?";
export const GET_ALL_DEEP_LINKING_COUNT = "deep_link/get_all_deep_links_count?";
export const DELETE_DEEP_LINKING_CLICKS = "deep_link/delete_deep_link_clicks?";
export const GET_DEEP_LINKING = "deep_link?deep_link_id=";
export const CREATE_DEEP_LINKING = "deep_link?deep_link_app_id=";
export const EDIT_DEEP_LINKING = "deep_link?deep_link_id=";
export const STATUS_DEEP_LINKING = "deep_link/deep_link_stats";

//custom qr code
export const CREATE_CUSTOM_QR_CODE = "qr_code";
export const CREATE_QR_CODE_SHORTEN_LINK = "qr_code/short_link";
export const GET_ALL_CUSTOM_QR_CODE = "qr_code/get_all_qr_code";
export const GET_CUSTOM_QR_CODE = "qr_code?qr_link_id=";
export const EDIT_CUSTOM_QR_CODE = "qr_code?qr_link_id=";

//bookmark btn
export const GET_BOOKMARK_URL = "bookmarklet/bookmark_url";
