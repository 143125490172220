import {
  Box,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDashboardDataPopular } from "../../Hooks/Dashboard/useDashboardCard";
import EmptyScreen from "../../Component/EmptyScreen";
import CircleLoader from "../../Component/CircleLoader";
import { TabPanel } from "../../Helpers/common";
import { useTranslation } from "react-i18next";
import { getDashboardSingleUrl } from "../../services/domainService";

const PopularLinks = ({ value, handleChangeLoading }) => {
  const { t } = useTranslation();
  const [shortUrlData, setShortUrlData] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    data: popularData,
    refetch: dashboardPopularData,
    isLoading: popularDataIsLoading,
    isFetching: popularDataIsFetching,
  } = useDashboardDataPopular(value === 0 ? true : false);

  useEffect(() => {
    if (popularData?.popularUrl) {
      popularData?.popularUrl?.map(async (item, index) => {
        setLoading(true);
        await getDashboardSingleUrl(item?._id).then((res) => {
          let data = res?.data?.data?.urlData;
          data = data.filter(
            (newItem) =>
              !shortUrlData.find(
                (existingItem) => existingItem._id === newItem._id
              )
          );
          setShortUrlData((prev) => [...data, ...prev]);
          setTimeout(() => {
            setLoading(false);
          }, [1000]);
        });
      });
    }
  }, [popularData?.popularUrl]);

  useEffect(() => {
    handleChangeLoading(
      popularDataIsLoading || popularDataIsFetching || loading
    );
  }, [popularDataIsLoading, popularDataIsFetching, loading]);

  useEffect(() => {
    dashboardPopularData();
  }, [value]);

  return (
    <>
      <TabPanel value={value} index={0}>
        <List disablePadding sx={{ "& .MuiListItem-root": { px: 3, py: 2.1 } }}>
          {popularDataIsLoading || popularDataIsFetching ? (
            <>
              <CircleLoader />
            </>
          ) : !!popularData && popularData?.latestUrl?.length > 0 ? (
            popularData?.latestUrl?.map((item, index) => {
              return (
                <ListItem
                  divider
                  secondaryAction={
                    <Stack spacing={0.25} alignItems="flex-end">
                      <Typography variant="subtitle1">
                        {item?.totalClicks || 0}
                      </Typography>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 0.5,
                        }}
                      >
                        {t("Clicks")}
                      </Typography>
                    </Stack>
                  }
                >
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle1"
                        // sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
                        sx={{
                          textOverflow: { xs: "ellipsis" },
                          overflow: { xs: "hidden" },
                          overflowWrap: { xs: "unset", xsm: "anywhere" },
                          textWrap: { xs: "wrap", xsm: "nowrap" },
                          fontSize: "15px",
                        }}
                        title={item?.short_url || ""}
                      >
                        {item?.short_url || ""}
                      </Typography>
                    }
                    secondary={
                      <Box
                        sx={{ display: "flex" }}
                        width={{ xs: "100%", xsm: "auto" }}
                      >
                        <img
                          src={`https://www.google.com/s2/favicons?domain=${
                            popularData && item?.is_rotator === true
                              ? item?.url
                              : item?.long_url || ""
                          }`}
                          alt={
                            popularData && item?.is_rotator === true
                              ? item && item?.url
                              : item?.long_url || ""
                          }
                          style={{ height: 25, width: 25, marginRight: 10 }}
                        />
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          // sx={{ overflowWrap: "anywhere" }}
                          sx={{
                            textOverflow: { xs: "ellipsis" },
                            overflow: { xs: "hidden" },
                            overflowWrap: { xs: "unset", xsm: "anywhere" },
                            textWrap: { xs: "wrap", xsm: "nowrap" },
                            fontSize: "13px",
                          }}
                          title={item?.short_url || ""}
                          width={"80%"}
                        >
                          {item?.is_rotator
                            ? item?.newUrl || item?.rotating_urls[0].url || ""
                            : item?.long_url || ""}
                        </Typography>
                      </Box>
                    }
                    sx={{ maxWidth: "90%" }}
                  />
                </ListItem>
              );
            })
          ) : (
            <ListItem>
              <ListItemText
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "400px",
                }}
              >
                <EmptyScreen msg={t("NoLinksFound")} />
              </ListItemText>
            </ListItem>
          )}
        </List>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <List disablePadding sx={{ "& .MuiListItem-root": { px: 3, py: 2.1 } }}>
          {popularDataIsLoading || popularDataIsFetching || loading ? (
            <>
              <CircleLoader />
            </>
          ) : !!popularData?.popularUrl?.length > 0 ? (
            popularData?.popularUrl?.map((item, index) => {
              return (
                <ListItem
                  divider
                  secondaryAction={
                    <Stack spacing={0.25} alignItems="flex-end">
                      <Typography variant="subtitle1">
                        {item?.clicks || 0}
                      </Typography>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 0.5,
                        }}
                      >
                        {t("Clicks")}
                      </Typography>
                    </Stack>
                  }
                >
                  <ListItemText
                    primary={
                      !!shortUrlData &&
                      shortUrlData.map((url, index) => {
                        return (
                          <Typography
                            variant="subtitle1"
                            sx={{
                              textOverflow: { xs: "ellipsis" },
                              overflow: { xs: "hidden" },
                              overflowWrap: { xs: "unset", xsm: "anywhere" },
                              textWrap: { xs: "wrap", xsm: "nowrap" },
                              fontSize: "15px",
                            }}
                          >
                            {url?.short_code === item?.short_code
                              ? url.short_url || ""
                              : ""}
                          </Typography>
                        );
                      })
                    }
                    secondary={
                      <Box sx={{ display: "flex" }}>
                        <img
                          src={`https://www.google.com/s2/favicons?domain=${
                            popularData && item?.is_rotator === true
                              ? item?.url
                              : item?.long_url || ""
                          }`}
                          alt={
                            popularData && item?.is_rotator === true
                              ? item && item?.url
                              : item?.long_url || ""
                          }
                          style={{ height: 25, width: 25, marginRight: 10 }}
                        />
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          sx={{
                            textOverflow: { xs: "ellipsis" },
                            overflow: { xs: "hidden" },
                            overflowWrap: { xs: "unset", xsm: "anywhere" },
                            textWrap: { xs: "wrap", xsm: "nowrap" },
                            fontSize: "13px",
                          }}
                          width={"80%"}
                        >
                          {item?.is_rotator
                            ? item?.newUrl || item?.rotating_urls[0].url || ""
                            : item?.long_url || ""}
                        </Typography>
                      </Box>
                    }
                    sx={{ maxWidth: "90%" }}
                  />
                </ListItem>
              );
            })
          ) : (
            <ListItem>
              <ListItemText
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "400px",
                }}
              >
                <EmptyScreen msg={t("NoLinksFound")} />
              </ListItemText>
            </ListItem>
          )}
        </List>
      </TabPanel>
    </>
  );
};

export default PopularLinks;
